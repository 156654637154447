import React from "react";

export default function ReportedMessageComponent({ reportedType }) {
  return (
    <p>
      {reportedType === 1 && <p style={{ fontSize: 14 }}>Wrong Video</p>}
      {reportedType === 2 && (
        <p style={{ fontSize: 14 }}>Inappropriate Video</p>
      )}
      {reportedType === 3 && <p style={{ fontSize: 14 }}>Cheating</p>}
      {reportedType === 4 && <p style={{ fontSize: 14 }}>Video Not Found</p>}
    </p>
  );
}
