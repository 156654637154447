import jwt_decode from "jwt-decode";

import { getCookie } from "./cookies";

export const getJwt = (ctx) => getCookie(ctx);

export const isAuthenticated = (ctx) => !!getJwt(ctx);

export const isLogin = () => {
  if (isAuthenticated("token")) return true;

  return false;
};

export const isVerified = () => {
  const decode = jwt_decode(getCookie("token"));

  if (decode.username && decode.password) return true;

  return false;
};
