import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../api/axios";
import { setCookie } from "../helpers/cookies";
import { isLogin } from "../helpers/auth";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [failed, setFailed] = useState(false);
  const navigate = useNavigate();

  const loginUser = async () => {
    try {
      const loginData = {
        username,
        password,
      };
      const response = await api.post("/admin/login", loginData);
      if (response.data.token) {
        setCookie("token", response.data.token);
        return navigate("/");
      }
    } catch (err) {
      setFailed(true);
    }
  };

  useEffect(() => {
    if (isLogin()) {
      return navigate("/");
    }
  }, []);

  return (
    <div className="loginBody">
      <input
        className="username"
        placeholder="Username.."
        onChange={(username) => setUsername(username.target.value)}
      />
      <br />
      <input
        className="password"
        type="password"
        placeholder="Password.."
        onChange={(password) => setPassword(password.target.value)}
      />
      <br />
      <button className="loginBtn" onClick={loginUser}>
        Login
      </button>
      {failed && (
        <div>
          <p className="error">Invalid credentials. Try again..</p>
        </div>
      )}
    </div>
  );
};

export default Login;
