import "./App.css";
import { Routes, BrowserRouter as Router, Route } from "react-router-dom";
import Login from "./pages/Login";
import Reports from "./pages/Reports";
import Home from "./pages/Home";

function App() {
  return (
    <Router>
      <Routes>
        {/* LOGIN */}
        <Route exact path="/login" element={<Login />} />

        {/* LIST PAGE(S) */}
        <Route exact path="/" element={<Home />} />
        <Route exact path="/reports" element={<Reports />} />
      </Routes>
    </Router>
  );
}

export default App;
