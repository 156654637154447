import api from "../api/axios";

const removeVideoFromReported = async (id, game) => {
  const response = await api.post("/removeVideoFromReported", {
    videoId: id,
    game,
  });
  return response;
};

const approveVideo = async (id, game) => {
  const response = await api.post("/publishVideo", { videoId: id, game });
  return response;
};

const deleteVideo = async (id) => {
  const response = await api.delete(`/rejectVideo?videoId=${id}`, {
    videoId: id,
  });
  return response;
};

const removeChessGameFromReported = async (id) => {
  const response = await api.post("/removeChessGameFromReported", {
    id,
  });
  return response;
};

const approveChessGame = async (id, game) => {
  const response = await api.post("/publishChessGame", { id });
  return response;
};

const deleteChessGame = async (id) => {
  const response = await api.delete(`/rejectChessGame?id=${id}`, {
    videoId: id,
  });
  return response;
};

export {
  removeVideoFromReported,
  approveVideo,
  deleteVideo,
  removeChessGameFromReported,
  approveChessGame,
  deleteChessGame,
};
